import { Component, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { convertSecToTime, fetchAPI } from "../../../../utils";
import AdminModal from "../../../../components/AdminModal";
import { useI18n } from "../../../../i18n";

const fetchPlayers = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/players", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

class Player {
  steam_id: string;
  name: string;
  rank: number;
  total_time: number;
  total_connect: number;
  bypassMaintenance: boolean;

  constructor(
    steam_id: string,
    name: string,
    rank: number,
    total_time: number,
    total_connect: number,
    bypassMaintenance: boolean,
  ) {
    this.steam_id = steam_id;
    this.name = name;
    this.rank = rank;
    this.total_time = total_time;
    this.total_connect = total_connect;
    this.bypassMaintenance = bypassMaintenance;
  }
}

const ServerPlayer: Component = () => {
  const [playersList, { mutate }] = createResource("playersList", fetchPlayers);
  const [currentPlayers, setCurrentPlayers] = createSignal(new Player("", "", 0, 0, 0, false));
  const [sortInfo, setSortInfo] = createSignal({
    dsc: true,
    lastKey: "",
  });
  const { t } = useI18n();

  const [loadSearch, setLoadSearch] = createSignal(false);

  async function sortPlayerBy(
    key: string = "name",
    inverseOrder: boolean = true,
    offset: number = 0,
    search: string = "",
  ) {
    let sortInfoValue = sortInfo();

    if (inverseOrder) {
      setSortInfo({
        dsc: sortInfoValue.lastKey === key ? !sortInfoValue.dsc : false,
        lastKey: key,
      });
    }

    let params = {
      order: !sortInfo().dsc ? "asc" : "desc",
      limit: !playersList().loading ? Number(playersList().limit || 50) : 50,
      offset: offset ? offset : !playersList().loading ? Number(playersList().offset || 0) : 0,
      searchColum: key,
      search: search,
    };

    // transform the object into a query string
    let queryString = Object.keys(params)
      .map((k) => `${k}=${(params as Record<string, any>)[k]}`)
      .join("&");

    setLoadSearch(true);
    mutate(() => {
      return {
        ...playersList(),
        rows: [],
      };
    });
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/players?${queryString}`, "GET");
    setLoadSearch(false);
    if (!res.ok) {
      return;
    }

    const data = await res.json();
    mutate(() => data);
  }

  function getSortIcon(key: string) {
    let sortInfoValue = sortInfo();
    if (sortInfoValue.lastKey === key) {
      return sortInfoValue.dsc ? "fas fa-sort-down" : "fas fa-sort-up";
    }
    return "fas fa-sort";
  }

  async function savePlayer() {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/players/${currentPlayers().steam_id}`,
      "PUT",
      currentPlayers(),
    );
    if (!res.ok) {
      return;
    }
    const ply = await res.json();
    mutate((prevPlayers) => prevPlayers?.map((p) => (p.steam_id === ply.steam_id ? ply : p)));
  }

  const [inputID, setInputID] = createSignal(0);
  return (
    <>
      <AdminModal title={t("dashboard.server.edit_player.title", "Edit Player")} id="edit_player">
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.edit_player.bypass_maintenance", "ByPass Maintenance")}</span>
          </label>
          <select
            class="select select-bordered"
            value={currentPlayers().bypassMaintenance ? "true" : "false"}
            onChange={(e) => {
              currentPlayers().bypassMaintenance = e.currentTarget.value === "true";
            }}
          >
            <option value="true">{t("dashboard.server.edit_player.yes", "Yes")}</option>
            <option value="false">{t("dashboard.server.edit_player.no", "No")}</option>
          </select>
        </div>

        <button
          class="btn btn-primary mt-2"
          onclick="edit_player.close()"
          onClick={async () => {
            await savePlayer();
          }}
        >
          {t("dashboard.server.edit_player.save", "Save")}
        </button>
      </AdminModal>

      <AdminPanel
        title={t("dashboard.server.players_database.title", "Players Database")}
        description={t("dashboard.server.players_database.description", "List of all players in the server")}
        type="none"
      >
        {/* search*/}
        <div class="p-4">
          <label class="input input-bordered flex items-center p-4 gap-4">
            <input
              type="text"
              class="grow"
              placeholder="Search for SteamID64, Name, Rank..."
              onInput={(e) => {
                const value = e.currentTarget.value;
                const currentID = inputID() + 1;
                setInputID(currentID);
                setTimeout(async () => {
                  if (currentID === inputID()) {
                    console.log(value);
                    await sortPlayerBy(playersList().query.searchColum, false, 0, value);
                  }
                }, 400);
              }}
            />
            <i class="fa-solid fa-search"></i>
          </label>
        </div>
        <table class="table table-auto table-fixed border-t border-primary">
          <thead>
            <tr class="text-white text-l hover:cursor-pointer">
              <th class="w-1/4" onClick={() => sortPlayerBy("name")}>
                {t("dashboard.server.players_database.name", "Name")} <i class={getSortIcon("name")}></i>
              </th>
              <th class="w-1/6 text-center" onClick={() => sortPlayerBy("rank")}>
                {t("dashboard.server.players_database.rank", "Rank")} <i class={getSortIcon("rank")}></i>
              </th>
              <th class="w-1/6 text-center" onClick={() => sortPlayerBy("total_time")}>
                {t("dashboard.server.players_database.total_time", "Total Time")}{" "}
                <i class={getSortIcon("total_time")}></i>
              </th>
              <th class="w-1/6 text-center" onClick={() => sortPlayerBy("total_connect")}>
                {t("dashboard.server.players_database.total_connect", "Total Connect")}{" "}
                <i class={getSortIcon("total_connect")}></i>
              </th>
              <th class="w-1/6 text-center" onClick={() => sortPlayerBy("bypassMaintenance")}>
                {t("dashboard.server.players_database.bypass_maintenance", "Bypass Maintenance")}{" "}
                <i class={getSortIcon("bypassMaintenance")}></i>
              </th>
              <th class="hover:cursor-default w-1/6 text-center">
                {t("dashboard.server.players_database.actions", "Actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            <Show when={!playersList.loading}>
              <Show when={loadSearch()}>
                <tr>
                  <td colSpan="6">
                    <div class="flex justify-center h-36">
                      <div class="loading loading-spinner loading-lg"></div>
                    </div>
                  </td>
                </tr>
              </Show>
              <For each={playersList().rows}>
                {(player) => (
                  <tr>
                    <td class="w-1/4 truncate overflow-hidden text-ellipsis whitespace-nowrap">
                      <span>{player.name}</span>
                    </td>
                    <td class="w-1/6 text-center">{player.rank}</td>
                    <td class="w-1/6 text-center">{convertSecToTime(player.total_time)}</td>
                    <td class="w-1/6 text-center">{player.total_connect}</td>
                    <td class="w-1/6 text-center">
                      <div class="flex gap-2 justify-center">
                        {player.bypassMaintenance ? (
                          <i class="fas fa-check text-green"></i>
                        ) : (
                          <i class="fas fa-times text-red"></i>
                        )}
                      </div>
                    </td>
                    <td class="w-1/6">
                      <div class="flex gap-2 justify-center">
                        <a
                          href={`https://steamcommunity.com/profiles/${player.steam_id}`}
                          target="_blank"
                          class="tooltip tooltip-info"
                          data-tip={t("dashboard.server.players_database.steam", "Steam")}
                        >
                          <i class="fa-brands fa-steam"></i>
                        </a>
                        <div
                          class="tooltip tooltip-info"
                          data-tip={t("dashboard.server.players_database.edit", "Edit")}
                        >
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onClick={() => {
                              setCurrentPlayers(player);
                              // @ts-ignore
                              edit_player.showModal();
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
              <tr>
                <td colSpan="6">
                  <div class="join flex justify-center">
                    {/* go to first*/}
                    {/* got to previous*/}
                    <button
                      class="btn btn-sm btn-outline btn-primary join-item"
                      onClick={() =>
                        sortPlayerBy(
                          playersList().query.searchColum,
                          true,
                          Math.max(0, playersList().query.offset - playersList().query.limit),
                        )
                      }
                      disabled={playersList().query.offset === 0}
                    >
                      <i class="fa-solid fa-chevron-left"></i>
                    </button>
                    {/* return to 0 */}
                    <button
                      class="join-item text-center btn btn-primary btn-sm btn-outline"
                      onClick={() => sortPlayerBy(sortInfo().lastKey, true, 0)}
                    >
                      {`${playersList().query.offset / playersList().query.limit + 1} / ${Math.ceil(
                        playersList().query.total / playersList().query.limit,
                      )}`}
                    </button>
                    {/* go to next*/}
                    <button
                      class="btn btn-sm btn-outline btn-primary join-item"
                      onClick={() =>
                        sortPlayerBy(
                          playersList().query.searchColum,
                          true,
                          playersList().query.offset + playersList().query.limit,
                        )
                      }
                      disabled={playersList().query.offset + playersList().query.limit >= playersList().query.total}
                    >
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                    {/* go to last*/}
                  </div>
                </td>
              </tr>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={playersList.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={playersList.error}>
            <tr>
              <td colSpan="4">{t("dashboard.server.players_database.failed_to_load", "Failed to load the links")}</td>
            </tr>
          </Match>
        </Switch>
      </AdminPanel>
    </>
  );
};

export default ServerPlayer;

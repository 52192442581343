import { Component, createEffect, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import { fetchAPI, isPremium } from "../../../../utils";
import AdminPanel from "../../../../components/AdminPanel";
import AdminModal from "../../../../components/AdminModal";
import "emoji-picker-element";
import { guildChannelsRefetch } from "../GuildInformations";
import AdminChannelSelector from "../../../../components/AdminChannelSelector";
import { useI18n } from "../../../../i18n";
import DiscordMessage from "../../../../components/discord/DiscordMessage";

const fetchStatusButtons = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchStatus = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchShowPlayerList = async () => {
  const res = await fetchAPI(
    "/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_player_list_status",
    "GET",
  );
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

//show_player_list_status
class StatusButton {
  id: number;
  emoji: string;
  name: string;
  url: string;
  enable: boolean;

  constructor(id: number, emoji: string, name: string, url: string, enable: boolean) {
    this.id = id;
    this.emoji = emoji;
    this.name = name;
    this.url = url;
    this.enable = enable;
  }
}

const ServerStatus: Component = () => {
  const [statusButtons, { mutate }] = createResource("statusButtons", fetchStatusButtons);
  const [selectStatusButton, setSelectStatusButton] = createSignal(new StatusButton(0, "", "", "", false));
  const [visibleEmojiPicker, setVisibleEmojiPicker] = createSignal(false);
  const [status, { mutate: statusMutate }] = createResource("status", fetchStatus);
  const [showPlayerList, { mutate: showPlayerListMutate }] = createResource("showPlayerList", fetchShowPlayerList);
  const { t } = useI18n();

  const sendStatus = async (channelID: string) => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "POST", {
      channelID,
    });
    if (!res.ok) {
      return;
    }
    const status = await res.json();
    statusMutate(status);
    return status;
  };

  const [format, setFormat] = createSignal("");
  const [preview, setPreview] = createSignal("");

  createEffect(() => {
    const previewValue = format()
      .replace("{name}", "John Doe")
      .replace("{steamID64}", "76500000000000000")
      .replace("{team}", "Citizen")
      .replace("{userGroup}", "user")
      .replace("{connectTime}", "00:00:00")
      .replace("{kills}", "0")
      .replace("{deaths}", "0")
      .replace("{position}", "0 0 0")
      .replace("{angle}", "0 0 0")
      .replace("{fps}", "0")
      .replace("{ping}", "0")
      .replace("{adjustedTime}", "0")
      .replace("{branch}", "main");
    setPreview(previewValue);
  });

  const [status_player_list_format, { mutate: statusPlayerListFormatMutate }] = createResource(async () => {
    const res = await fetchAPI(
      "/users/:discordID/guilds/:guildID/servers/:serverID/settings/status_player_list_format",
      "GET",
    );
    if (!res.ok) {
      return {};
    }
    const data = await res.json();
    setFormat(data.value);
    return data;
  });

  function updateStatusPlayerListFormat(value: string) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/status_player_list_format", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        statusPlayerListFormatMutate(data);
        // @ts-ignore
        edit_format.close();
      });
  }

  const [show_status_chart, { mutate: showStatusChartMutate }] = createResource(async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_status_chart", "GET");
    if (!res.ok) {
      return {};
    }
    return await res.json();
  });

  function updateShowStatusChart(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_status_chart", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        showStatusChartMutate(data);
      });
  }

  const removeStatus = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "DELETE");
    if (!res.ok) {
      return;
    }
    statusMutate({});
  };

  const createStatusButton = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons", "POST");
    if (!res.ok) {
      return;
    }
    const button = await res.json();
    mutate((prevButtons) =>
      prevButtons
        ? [...prevButtons, new StatusButton(button.id, button.emoji, button.name, button.url, button.enable)]
        : [],
    );
    return button;
  };

  const deleteStatusButton = async (button: StatusButton) => {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons/${button.id}`,
      "DELETE",
    );
    if (!res.ok) {
      return;
    }
    mutate((prevButtons) => prevButtons?.filter((b) => b.id !== button.id));
  };

  const editStatusButton = async (button: StatusButton) => {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons/${button.id}`,
      "PUT",
      button,
    );
    if (!res.ok) {
      return;
    }
    const newButton = await res.json();
    mutate((prevButtons) => prevButtons?.map((b) => (b.id === newButton.id ? newButton : b)));
    return newButton;
  };

  const handleEmojiClick = (event: CustomEvent) => {
    setSelectStatusButton({ ...selectStatusButton(), emoji: event.detail.unicode });
    setVisibleEmojiPicker(false);
  };

  async function editPlayerListMutate(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_player_list_status", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        showPlayerListMutate(data);
      });
  }

  // @ts-ignore
  return (
    <>
      <AdminChannelSelector id="select_channel_modal" callback={sendStatus} />

      <AdminModal title={t("dashboard.server.pseudo.editFormat", "Edit Format")} id="edit_format">
        <Show when={!status_player_list_format.loading}>
          <div class="form-control p-2">
            <h1 class="text-lg text-gray-300 font-bold">{t("dashboard.server.pseudo.variables", "Variables")}</h1>
            <ul class="text-gray-400 list-inside">
              <li>{`{name} - ${t("dashboard.server.status.playerName", "Player Name")}`}</li>
              <li>{`{steamID64} - ${t("dashboard.server.status.playerSteamID64", "Player SteamID64")}`}</li>
              <li>{`{team} - ${t("dashboard.server.status.team", "Team")}`}</li>
              <li>{`{userGroup} - ${t("dashboard.server.status.userGroup", "User Group")}`}</li>
              <li>{`{connectTime} - ${t("dashboard.server.status.connectTime", "Connect Time")}`}</li>
              <li>{`{kills} - ${t("dashboard.server.status.kills", "Kills")}`}</li>
              <li>{`{deaths} - ${t("dashboard.server.status.deaths", "Deaths")}`}</li>
              <li>{`{position} - ${t("dashboard.server.status.position", "Position")}`}</li>
              <li>{`{angle} - ${t("dashboard.server.status.angle", "Angle")}`}</li>
              <li>{`{fps} - ${t("dashboard.server.status.fps", "FPS")}`}</li>
              <li>{`{ping} - ${t("dashboard.server.status.ping", "Ping")}`}</li>
              <li>{`{adjustedTime} - ${t("dashboard.server.status.adjustedTime", "Adjusted Time")}`}</li>
              <li>{`{branch} - ${t("dashboard.server.status.branch", "Branch")}`}</li>
            </ul>
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.pseudo.format", "Format")}</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={status_player_list_format().value}
              onInput={(e) => {
                setFormat(e.currentTarget.value);
              }}
            />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.pseudo.preview", "Preview")}</span>
            </label>
            <input type="text" class="input input-bordered" value={preview()} readonly />
          </div>
          <button
            class="btn btn-primary mt-2"
            onclick="edit_server.close()"
            onClick={async () => {
              updateStatusPlayerListFormat(format());
            }}
          >
            {t("dashboard.server.pseudo.save", "Save")}
          </button>
        </Show>
      </AdminModal>

      <AdminModal title={t("dashboard.server.status.edit_button", "Edit Button")} id="edit_status_button">
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.status.select_emoji", "Select an Emoji")}</span>
          </label>
          <button class="input input-bordered text-left" onClick={() => setVisibleEmojiPicker(!visibleEmojiPicker())}>
            {selectStatusButton().emoji}
          </button>
          <Show when={visibleEmojiPicker()}>
            {/*// @ts-ignore*/}
            <emoji-picker emoji-version="12.0" onEmoji-click={handleEmojiClick}></emoji-picker>
          </Show>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.status.button_name", "Button Name")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectStatusButton().name}
            onChange={(e) => setSelectStatusButton({ ...selectStatusButton(), name: e.currentTarget.value })}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.status.button_url", "Button URL")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectStatusButton().url}
            onChange={(e) => setSelectStatusButton({ ...selectStatusButton(), url: e.currentTarget.value })}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.status.button_action", "Button Action")}</span>
          </label>
          <select
            class="select select-bordered"
            value={selectStatusButton().enable ? "true" : "false"}
            onChange={(e) => {
              selectStatusButton().enable = e.currentTarget.value === "true";
            }}
          >
            <option value="true">{t("dashboard.server.status.yes", "Yes")}</option>
            <option value="false">{t("dashboard.server.status.no", "No")}</option>
          </select>
        </div>

        <button
          class="btn btn-primary mt-2"
          onclick="edit_status_button.close()"
          onClick={async () => {
            await editStatusButton(selectStatusButton());
          }}
        >
          {t("dashboard.server.status.save", "Save")}
        </button>
      </AdminModal>

      <AdminPanel
        title={t("dashboard.server.status.title", "Server Status")}
        description={t("dashboard.server.status.description", "Here you can see the current status of your server.")}
      >
        <div className="flex w-fit items-center">
          <span className="label-text mr-2">{t("dashboard.server.status.status_message", "Status Message")}:</span>
          <Show
            when={!status.loading && status().channel}
            fallback={
              <span className="label-text">{t("dashboard.server.status.no_status_message", "No status message")}</span>
            }
          >
            <DiscordMessage messageID={status().message} channelID={status().channel} />
          </Show>
        </div>
        <div className="flex w-fit items-center">
          <span className="label-text mr-2">{t("dashboard.server.status.show_player_list", "Show Player List")}:</span>
          <input
            type="checkbox"
            className="toggle toggle-md"
            checked={!showPlayerList.loading ? (showPlayerList() ? showPlayerList().value : false) : false}
            onChange={async (e) => {
              await editPlayerListMutate(e.currentTarget.checked);
            }}
          />
        </div>
        <div
          className="flex w-fit items-center tooltip-warning"
          classList={{
            tooltip: !isPremium(),
          }}
          data-tip={t("dashboard.server.status.premium_only", "This feature is only available for premium users.")}
        >
          <span className="label-text mr-2">
            {t("dashboard.server.status.show_player_chart", "Show Player Chart")}:
          </span>
          <input
            type="checkbox"
            className="toggle toggle-md"
            disabled={!isPremium()}
            checked={!show_status_chart.loading ? (show_status_chart() ? show_status_chart().value : false) : false}
            onChange={async (e) => {
              await updateShowStatusChart(e.currentTarget.checked);
            }}
          />
        </div>

        <div className="flex w-fit items-center">
          <span className="label-text mr-2">{t("dashboard.server.status.format", "Player List Format")}:</span>
          <input type="text" className="input input-bordered" value={preview()} readOnly />
        </div>

        <div className="flex gap-4">
          <div className="flex w-fit items-center gap-4">
            <button className="btn btn-primary" onClick="edit_format.showModal()">
              {t("dashboard.server.pseudo.editFormat", "Edit Format")}
            </button>
          </div>
          <Show when={!status.loading && status().channel}>
            <button
              className="btn btn-warning"
              onClick={async () => {
                await removeStatus();
              }}
            >
              {t("dashboard.server.status.remove_status", "Remove Status")}
            </button>
          </Show>
          <button
            className="btn btn-primary"
            onClick="select_channel_modal.showModal()"
            onClick={() => guildChannelsRefetch()}
          >
            {t("dashboard.server.status.send_status", "Send Status")}
          </button>
        </div>
      </AdminPanel>

      <AdminPanel
        title={t("dashboard.server.status.status_buttons", "Status Buttons")}
        description={t(
          "dashboard.server.status.status_buttons_description",
          "Add utility buttons to your server status message.",
        )}
        type="none"
        premium={t("dashboard.server.status.premium", "Limited to 3 buttons for free users.")}
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>{t("dashboard.server.status.button_icon", "Icon")}</th>
              <th>{t("dashboard.server.status.button_name", "Name")}</th>
              <th>{t("dashboard.server.status.button_url", "URL")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.status.active", "Active")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.status.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!statusButtons.loading}>
              <For each={statusButtons()}>
                {(button) => (
                  <tr>
                    <td>{button.emoji}</td>
                    <td>{button.name}</td>
                    <td>{button.url}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {button.enable ? (
                          <i class="fas fa-check text-green"></i>
                        ) : (
                          <i class="fas fa-times text-red"></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-info" data-tip={t("dashboard.server.status.edit", "Edit")}>
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_status_button.showModal()"
                            onClick={() => setSelectStatusButton(button)}
                          ></i>
                        </div>
                        <div class="tooltip tooltip-error" data-tip={t("dashboard.server.status.delete", "Delete")}>
                          <i
                            class="hover:cursor-pointer fas fa-trash text-red"
                            onClick={() => deleteStatusButton(button)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={statusButtons.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={statusButtons.error}>
            <tr>
              <td colSpan="4">{t("dashboard.server.status.failed_to_load", "Failed to load the links")}</td>
            </tr>
          </Match>
        </Switch>

        <div class="flex gap-4 p-4">
          <button
            class="btn btn-primary"
            onClick={() => createStatusButton()}
            disabled={!isPremium() && (!statusButtons() || statusButtons().length >= 3)}
          >
            {t("dashboard.server.status.add_button", "Add Button")}
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerStatus;
